import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

window.addEventListener('load', () => {
  if (window.innerWidth > 1025) {
    gsap.to(".scroll-media__background", {
      clipPath: "inset(0% 0%)",
      scale: '1',
      opacity: '1',
      duration: 2,
      ease: "slow",
      scrollTrigger: {
        trigger: ".scroll-media__background",
        start: "top 80%",
        end: "center bottom",
        scrub: 1,
      },
    });

    gsap.to(".scroll-media__video", {
      y: 0,
      duration: 2,
      ease: "slow",
      scrollTrigger: {
        trigger: ".scroll-media__background",
        start: "top 80%",
        end: "bottom center",
        scrub: 1.5,
      },
    });
  }
});
